import React from 'react';
import { Card, CardContent } from '../../../lib/card';
import {
  BookOpen,
  Search,
  GitMerge,
  History,
  FileText,
  Link2,
  ArrowRight,
  CheckCircle2,
  Library,
  Network
} from 'lucide-react';
import { Container } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export const KnowledgeBasePage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="min-h-screen bg-gray-50">
        {/* Navigation */}
        {/* <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="text-2xl font-bold text-blue-600">KORU</div>
            <div className="flex space-x-4">
              <button className="text-gray-600 hover:text-gray-900">
                Help
              </button>
              <button className="text-gray-600 hover:text-gray-900">
                Profile
              </button>
            </div>
          </div>
        </div>
      </nav> */}

        {/* Hero Section */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <div className="flex flex-col md:flex-row items-center gap-12">
              <div className="flex-1">
                <h1 className="text-4xl font-bold text-gray-900 mb-6">
                  Build Your Organization's Knowledge Base
                </h1>
                <p className="text-xl text-gray-600 mb-8">
                  Create a centralized, searchable library of document expertise
                  that grows with each project. Connect related information,
                  maintain institutional knowledge, and ensure consistency
                  across your organization.
                </p>
                <button
                  className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 inline-flex items-center gap-2"
                  onClick={() => navigate('/app/projects')}
                >
                  Start Building <ArrowRight className="w-4 h-4" />
                </button>
              </div>
              <div className="flex-1 flex justify-center">
                <Library className="w-64 h-64 text-blue-600" />
              </div>
            </div>
          </div>
        </div>

        {/* Main Features Grid */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <h2 className="text-3xl font-bold text-center mb-12">
            Knowledge Base Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            <Card>
              <CardContent className="p-6">
                <Search className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">Smart Search</h3>
                <p className="text-gray-600 mb-4">
                  Perform unified searches across all project documents and
                  knowledge base content.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1 shrink-0" />
                    <span className="text-gray-600">
                      Search within specific folders or across entire knowledge
                      base
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1 shrink-0" />
                    <span className="text-gray-600">
                      Find similar requirements and obligations
                    </span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-6">
                <Network className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">
                  Connected Information
                </h3>
                <p className="text-gray-600 mb-4">
                  Create a network of connected requirements and supporting
                  information.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1 shrink-0" />
                    <span className="text-gray-600">
                      Link related requirements across documents
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1 shrink-0" />
                    <span className="text-gray-600">
                      Connect supplementary technical information
                    </span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-6">
                <History className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">
                  Evolution Tracking
                </h3>
                <p className="text-gray-600 mb-4">
                  Track how requirements and obligations change over time.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1 shrink-0" />
                    <span className="text-gray-600">
                      Monitor requirement changes across document versions
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1 shrink-0" />
                    <span className="text-gray-600">
                      Maintain history of interpretations and decisions
                    </span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>

          {/* Analysis Capabilities */}
          <div className="bg-white rounded-lg p-8 mb-16">
            <h2 className="text-2xl font-bold mb-8">Analysis Capabilities</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                  <GitMerge className="w-6 h-6 text-blue-600" />
                  Cross-Document Analysis
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1" />
                    <span className="text-gray-600">
                      Identify dependencies between obligations
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1" />
                    <span className="text-gray-600">
                      Detect potential conflicts between requirements
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1" />
                    <span className="text-gray-600">
                      Track changes across document versions
                    </span>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                  <Link2 className="w-6 h-6 text-blue-600" />
                  Comparative Analysis
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1" />
                    <span className="text-gray-600">
                      Compare obligations across different agreements
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1" />
                    <span className="text-gray-600">
                      Analyze requirement coverage
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="w-5 h-5 text-green-500 mt-1" />
                    <span className="text-gray-600">
                      Examine consistency in obligation parameters
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Get Started Section */}
          <Card className="bg-blue-50 border-blue-200">
            <CardContent className="p-8">
              <div className="flex flex-col md:flex-row items-center gap-8">
                <div className="flex-1">
                  <h2 className="text-2xl font-bold mb-4">
                    Ready to Get Started?
                  </h2>
                  <p className="text-gray-600 mb-6">
                    Begin building your organization's knowledge base today. Our
                    team can help you set up and organize your first project.
                  </p>
                  <div className="flex gap-4">
                    <button
                      className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
                      onClick={() => navigate('/app/projects')}
                    >
                      Create Project
                    </button>
                    {/* <button className="bg-white text-blue-600 px-6 py-2 rounded-md border border-blue-600 hover:bg-blue-50">
                      Schedule Demo
                    </button> */}
                  </div>
                </div>
                <div className="flex-1 flex justify-center">
                  <BookOpen className="w-32 h-32 text-blue-600" />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </Container>
  );
};
