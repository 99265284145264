import { ExternalLink } from 'lucide-react';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { ChatResponseSentence } from '../../../models/interfaces';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import { UserContext } from '../../../context/authContext';
import { ProjectContext } from '../../../context/projectContext';

export const ContentItem: React.FC<{
  // text: string;
  // source: string;
  // page: string;
  // folderPath?: string;
  // tag?: string;
  // context?: { before: string; after: string };
  sentence: ChatResponseSentence;
  // }> = ({ text, source, page, folderPath = '', tag, context }) => {
}> = ({ sentence }) => {
  const { auth } = useContext(UserContext);
  const [showContext, setShowContext] = useState(false);
  const [context, setContext] = useState<{ before: string; after: string }>({
    before: '',
    after: ''
  });
  const splitStringBySubstring = (
    input: string,
    substring: string
  ): [string, string, string] => {
    const index = input.indexOf(substring);

    if (index === -1) {
      return [input, '', '']; // Return whole string if substring not found
    }

    const before = input.substring(0, index);
    const after = input.substring(index + substring.length);

    return [before, substring, after];
  };
  useEffect(() => {
    if (sentence.Context.text) {
      const result = splitStringBySubstring(
        sentence.Context.text,
        sentence.text
      );
      if (result[1] === '') {
        const toSet = {
          before: '',
          after: ''
        };
        setContext(toSet);
      } else {
        const toSet = {
          before: result[0],
          after: result[2]
        };
        setContext(toSet);
      }
    }
  }, []);

  return (
    <div className="relative border-l-2 border-slate-300 pl-4 mb-4 group">
      <div className="flex items-start gap-2">
        {/* {tag && (
          <span className="text-xs px-2 py-0.5 rounded bg-amber-50 text-amber-700">
            {tag}
          </span>
        )} */}
        <div className="space-y-2 w-full">
          <p className="text-sm text-slate-800">{sentence.text}</p>
          <div className="flex items-center justify-between text-xs">
            <div className="flex items-center gap-2">
              {sentence.folder.length > 0 && sentence.project.length > 0 && (
                <div className="flex items-center gap-1 text-slate-500">
                  <React.Fragment>
                    <span className="mx-1 text-slate-400">
                      {sentence.project[0].projectName}
                    </span>

                    <span className="mx-1 text-slate-400">/</span>
                    <span className="mx-1 text-slate-400">
                      {sentence.folder[0].folderName}
                    </span>
                    <span className="mx-1 text-slate-400">/</span>
                  </React.Fragment>
                </div>
              )}
              <span className="text-slate-500">
                {sentence.Document.documentFileName} • Pg{' '}
                {sentence.PageNumber.toString()}
              </span>
            </div>
            <div className="flex gap-4 items-center">
              {sentence.Context && (
                <div className="relative">
                  <button
                    className="text-blue-600 hover:text-blue-800 transition-colors"
                    onMouseEnter={() => setShowContext(true)}
                    onMouseLeave={() => setShowContext(false)}
                  >
                    View Context
                  </button>
                  {showContext && (
                    <div className="absolute right-1 top-6 w-96 bg-white p-4 rounded-lg shadow-lg border border-slate-200 z-10">
                      <p className="text-sm text-slate-600 leading-relaxed">
                        <span className="text-slate-400">...</span>{' '}
                        {context.before}{' '}
                        <span className="bg-yellow-100 px-1">
                          {sentence.text}
                        </span>{' '}
                        {context.after}{' '}
                        <span className="text-slate-400">...</span>
                      </p>
                    </div>
                  )}
                </div>
              )}
              <button
                className="text-blue-600 hover:text-blue-800 transition-colors flex items-center gap-1"
                onClick={() =>
                  getKBDocLinkAndOpen(
                    sentence.Document._id,
                    sentence.PageNumber,
                    auth
                  )
                }
              >
                View Source <ExternalLink className="h-3 w-3" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
