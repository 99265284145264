import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Home, Folder, Folders } from 'lucide-react';
import { FolderItem } from '../../../models/interfaces';
interface HomeProps {
  showBackButton: boolean;
  currentFolder: string | null;
  projectId: string | null;
}
export const BackAndHomeButtons: React.FC<HomeProps> = ({
  showBackButton,
  currentFolder,
  projectId
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  const handleHome = () => {
    navigate('/'); // Navigates to the home page
  };
  const handleFolder = () => {
    navigate(`/app/projects/${projectId}/folder/${currentFolder}`);
  };
  const handleProject = () => {
    navigate(`/app/projects/${projectId}`);
  };

  return (
    <div className="flex items-left justify-start gap-4 p-2">
      <button
        onClick={handleHome}
        className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
        aria-label="Home"
      >
        <Home className="w-3 h-3" />
      </button>
      {projectId ? (
        <button
          onClick={handleProject}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Folder"
        >
          <Folders className="w-3 h-3" />
        </button>
      ) : null}
      {currentFolder ? (
        <button
          onClick={handleFolder}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Folder"
        >
          <Folder className="w-3 h-3" />
        </button>
      ) : null}
      {showBackButton && (
        <button
          onClick={handleBack}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Back"
        >
          <ArrowLeft className="w-3 h-3" />
        </button>
      )}
    </div>
  );
};
export const DocumentViewButtons: React.FC<HomeProps> = ({
  showBackButton,
  currentFolder,
  projectId
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  const handleHome = () => {
    navigate('/'); // Navigates to the home page
  };
  const handleFolder = () => {
    navigate(`/app/projects/${projectId}/folder/${currentFolder}`);
  };
  const handleProject = () => {
    navigate(`/app/projects/${projectId}`);
  };

  return (
    <div className="flex items-left justify-start gap-4 p-0 ">
      <button
        onClick={handleHome}
        className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
        aria-label="Home"
      >
        <Home className="w-3 h-3" />
      </button>
      {projectId ? (
        <button
          onClick={handleProject}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Folder"
        >
          <Folders className="w-3 h-3" />
        </button>
      ) : null}
      {currentFolder ? (
        <button
          onClick={handleFolder}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Folder"
        >
          <Folder className="w-3 h-3" />
        </button>
      ) : null}
      {showBackButton && (
        <button
          onClick={handleBack}
          className="p-2 border border-blue-500 rounded-full hover:bg-blue-100 text-blue-500 hover:text-blue-600 transition duration-200"
          aria-label="Back"
        >
          <ArrowLeft className="w-3 h-3" />
        </button>
      )}
    </div>
  );
};
