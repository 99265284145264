import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../../lib/card';
import {
  Search,
  Filter,
  ExternalLink,
  ChevronDown,
  ChevronUp,
  Download,
  FileText
} from 'lucide-react';
interface CategorySet {
  [key: string]: boolean;
}

interface SelectedCategories {
  obligations: CategorySet;
  resources: CategorySet;
  timeframes: CategorySet;
  general: CategorySet;
}
const NewChatLayout = () => {
  // this is the search query
  const [searchQuery, setSearchQuery] = React.useState('site preparation');
  const [showCategoryPanel, setShowCategoryPanel] = React.useState(false);
  const [selectedCategories, setSelectedCategories] =
    React.useState<SelectedCategories>({
      obligations: {
        'Regulatory Compliance': true,
        'Safety Protocols': true
      },
      resources: {
        Equipment: true,
        'Materials and Supplies': true,
        Labor: true,
        'Project Timeline': true
      },
      timeframes: {
        'Work Schedules': true,
        'Financial Due Dates': true,
        'General Timeframes': true
      },
      general: {
        'Other Relevant Information': true
      }
    });

  // Common button styles
  const primaryButtonClasses =
    'flex items-center gap-2 bg-blue-100 hover:bg-blue-200 text-blue-700 px-3 py-1.5 rounded-md transition-colors';
  const secondaryButtonClasses =
    'flex items-center gap-1 bg-white hover:bg-gray-50 text-blue-600 px-2 py-1 rounded text-xs border border-gray-200 transition-colors';
  const viewSourceClasses =
    'flex items-center gap-1 bg-white hover:bg-gray-50 text-blue-600 px-2 py-1 rounded text-xs border border-gray-200 transition-colors';

  // Reusable components
  const SectionTitle: React.FC<{ title: string }> = ({ title }) => (
    <div className="border-b border-slate-200 pb-2">
      <h3 className="font-medium text-slate-900">{title}</h3>
    </div>
  );

  const ContentItem: React.FC<{
    text: string;
    source: string;
    page: string;
    folderPath?: string;
    tag?: string;
    context?: { before: string; after: string };
  }> = ({ text, source, page, folderPath = '', tag, context }) => {
    const [showContext, setShowContext] = React.useState(false);

    return (
      <div className="relative border-l-2 border-slate-300 pl-4 mb-4 group">
        <div className="flex items-start gap-2">
          {tag && (
            <span className="text-xs px-2 py-0.5 rounded bg-amber-50 text-amber-700">
              {tag}
            </span>
          )}
          <div className="space-y-2 w-full">
            <p className="text-sm text-slate-800">{text}</p>

            <div className="flex items-center justify-between text-xs">
              <div className="flex items-center gap-2">
                <span className="text-slate-500">
                  {source} • Pg {page}
                </span>
                {folderPath && (
                  <div className="flex items-center gap-1 text-slate-500">
                    {folderPath.split('/').map((folder, index, array) => (
                      <React.Fragment key={index}>
                        <span className="hover:text-blue-600 cursor-pointer">
                          {folder}
                        </span>
                        {index < array.length - 1 && (
                          <span className="mx-1 text-slate-400">/</span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex gap-4 items-center">
                {context && (
                  <div className="relative">
                    <button
                      className="text-blue-600 hover:text-blue-800 transition-colors"
                      onMouseEnter={() => setShowContext(true)}
                      onMouseLeave={() => setShowContext(false)}
                    >
                      View Context
                    </button>
                    {showContext && (
                      <div className="absolute left-0 top-6 w-96 bg-white p-4 rounded-lg shadow-lg border border-slate-200 z-10">
                        <p className="text-sm text-slate-600 leading-relaxed">
                          <span className="text-slate-400">...</span>{' '}
                          {context.before}{' '}
                          <span className="bg-yellow-100 px-1">{text}</span>{' '}
                          {context.after}{' '}
                          <span className="text-slate-400">...</span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <button className="text-blue-600 hover:text-blue-800 transition-colors flex items-center gap-1">
                  View Source <ExternalLink className="h-3 w-3" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  interface SourceDocumentProps {
    title: string;
    page: number;
    folderPath?: string;
    excerpt: string;
    section: string;
  }
  const SourceDocument: React.FC<SourceDocumentProps> = ({
    title,
    page,
    folderPath = '',
    excerpt,
    section
  }) => (
    <Card className="border shadow-sm">
      <CardContent className="p-4">
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FileText className="h-4 w-4 text-gray-400" />
              <span className="text-sm font-medium">{title}</span>
              <span className="text-xs text-gray-500">Page {page}</span>
            </div>
            {folderPath && (
              <div className="flex items-center gap-1 text-xs text-gray-600">
                {folderPath.split('/').map((folder, index, array) => (
                  <React.Fragment key={index}>
                    <span className="hover:text-blue-600 cursor-pointer">
                      {folder}
                    </span>
                    {index < array.length - 1 && (
                      <span className="mx-1 text-gray-400">/</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>

          <div className="bg-gray-50 p-3 rounded-md">
            <p className="text-sm text-gray-600">
              <span className="text-gray-400">...</span> {excerpt}{' '}
              <span className="text-gray-400">...</span>
            </p>
          </div>

          <div className="flex items-center justify-between text-xs">
            <span className="text-gray-500">{section}</span>
            <div className="flex gap-2">
              <button className={secondaryButtonClasses}>View Context</button>
              <button className={primaryButtonClasses}>Open Document</button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div className="container mx-auto p-6 space-y-6">
      {/* Application Header */}
      {/* <div className="pb-6 border-b border-gray-200">
        <h1 className="text-3xl font-bold text-gray-900 mb-3">
          KORU Intelligent Search
        </h1>
        <div className="max-w-3xl">
          <p className="text-gray-600">
            Discover important information with full traceability to key
            sections in your source documentation.
          </p>
        </div>
      </div> */}

      {/* Search Controls */}
     

      {/* Results Overview */}
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h2 className="text-lg font-medium text-gray-900">Search Results</h2>
          <p className="text-sm text-gray-600">
            Found 15 relevant items in selected documents for "site preparation"
          </p>
        </div>
      </div>

      {/* Structured Summary Section */}
      <Card className="w-full bg-slate-50 border-slate-200">
        <CardHeader className="pb-2 bg-slate-100 rounded-t-lg border-b border-slate-200">
          <div className="flex items-center justify-between">
            <div>
              <CardTitle className="text-lg text-slate-800">
                Structured Summary
              </CardTitle>
              <p className="text-sm text-slate-600">
                Search results organized by category with{' '}
                {searchQuery ? `"${searchQuery}"` : ''} highlighted
              </p>
            </div>
            <div className="flex items-center gap-3">
              <div className="relative">
                <button
                  className={`flex items-center gap-2 ${showCategoryPanel ? 'bg-blue-50 text-blue-800' : 'text-blue-600 hover:text-blue-800'} px-3 py-1.5 rounded-md transition-colors`}
                  onClick={() => setShowCategoryPanel(!showCategoryPanel)}
                >
                  <Filter className="h-4 w-4" />
                  <span>Customize Categories</span>
                </button>

                {showCategoryPanel && (
                  <div className="absolute right-0 top-full mt-2 w-72 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                    <div className="p-4 space-y-4">
                      <div>
                        <h4 className="font-medium text-gray-900 mb-2">
                          Obligations & Requirements
                        </h4>
                        <div className="space-y-2">
                          {Object.entries(selectedCategories.obligations).map(
                            ([category, isSelected]) => (
                              <label
                                key={category}
                                className="flex items-center gap-2"
                              >
                                <input
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() =>
                                    setSelectedCategories((prev) => ({
                                      ...prev,
                                      obligations: {
                                        ...prev.obligations,
                                        [category]: !isSelected
                                      }
                                    }))
                                  }
                                  className="rounded border-gray-300"
                                />
                                <span className="text-sm text-gray-600">
                                  {category}
                                </span>
                              </label>
                            )
                          )}
                        </div>
                      </div>

                      <div>
                        <h4 className="font-medium text-gray-900 mb-2">
                          Resource Planning & Logistics
                        </h4>
                        <div className="space-y-2">
                          {Object.entries(selectedCategories.resources).map(
                            ([category, isSelected]) => (
                              <label
                                key={category}
                                className="flex items-center gap-2"
                              >
                                <input
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() =>
                                    setSelectedCategories((prev) => ({
                                      ...prev,
                                      resources: {
                                        ...prev.resources,
                                        [category]: !isSelected
                                      }
                                    }))
                                  }
                                  className="rounded border-gray-300"
                                />
                                <span className="text-sm text-gray-600">
                                  {category}
                                </span>
                              </label>
                            )
                          )}
                        </div>
                      </div>

                      <div>
                        <h4 className="font-medium text-gray-900 mb-2">
                          Timeframes
                        </h4>
                        <div className="space-y-2">
                          {Object.entries(selectedCategories.timeframes).map(
                            ([category, isSelected]) => (
                              <label
                                key={category}
                                className="flex items-center gap-2"
                              >
                                <input
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() =>
                                    setSelectedCategories((prev) => ({
                                      ...prev,
                                      timeframes: {
                                        ...prev.timeframes,
                                        [category]: !isSelected
                                      }
                                    }))
                                  }
                                  className="rounded border-gray-300"
                                />
                                <span className="text-sm text-gray-600">
                                  {category}
                                </span>
                              </label>
                            )
                          )}
                        </div>
                      </div>

                      <div>
                        <h4 className="font-medium text-gray-900 mb-2">
                          General
                        </h4>
                        <div className="space-y-2">
                          {Object.entries(selectedCategories.general).map(
                            ([category, isSelected]) => (
                              <label
                                key={category}
                                className="flex items-center gap-2"
                              >
                                <input
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() =>
                                    setSelectedCategories((prev) => ({
                                      ...prev,
                                      general: {
                                        ...prev.general,
                                        [category]: !isSelected
                                      }
                                    }))
                                  }
                                  className="rounded border-gray-300"
                                />
                                <span className="text-sm text-gray-600">
                                  {category}
                                </span>
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <button className={primaryButtonClasses}>
                <Download className="h-4 w-4" />
                <span>Export Summary</span>
              </button>
            </div>
          </div>
        </CardHeader>

        <CardContent className="space-y-8">
          {/* OBLIGATIONS & REQUIREMENTS */}
          {Object.keys(selectedCategories.obligations).some(
            (key) => selectedCategories.obligations[key]
          ) && (
            <div>
              <SectionTitle title="OBLIGATIONS & REQUIREMENTS" />
              <div className="space-y-4 mt-4">
                {selectedCategories.obligations['Regulatory Compliance'] && (
                  <div className="pl-4">
                    <h4 className="font-medium text-gray-900 mb-3">
                      Regulatory Compliance
                    </h4>
                    <ContentItem
                      text="All site preparation activities must comply with Environmental Protection Agency guidelines section 7.2"
                      source="Environmental Compliance Doc"
                      page="15"
                      folderPath="Hospital Construction/Environmental/Guidelines"
                      context={{
                        before:
                          'In accordance with local regulations and EPA standards,',
                        after:
                          'with particular attention to dust control and water runoff management during the construction phase.'
                      }}
                      tag={undefined}
                    />
                  </div>
                )}

                {selectedCategories.obligations['Safety Protocols'] && (
                  <div className="pl-4">
                    <h4 className="font-medium text-gray-900 mb-3">
                      Safety Protocols
                    </h4>
                    <ContentItem
                      text="Daily safety briefings required before commencing excavation work"
                      source="Safety Guidelines"
                      page="8"
                      folderPath="Hospital Construction/Health & Safety/Protocols"
                      context={{
                        before:
                          'To maintain a safe working environment and ensure OSHA compliance,',
                        after:
                          "These briefings must cover all active operations and potential hazards identified for that day's activities."
                      }}
                      tag={undefined}
                    />
                    <ContentItem
                      text="Personal protective equipment must be worn at all times during site preparation"
                      source="Safety Protocol"
                      page="12"
                      folderPath="Hospital Construction/Health & Safety/PPE Requirements"
                      context={{
                        before:
                          'Worker safety is of paramount importance during all construction activities.',
                        after:
                          'This includes hard hats, safety glasses, high-visibility vests, and steel-toed boots at minimum.'
                      }}
                      tag={undefined}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* RESOURCE PLANNING & LOGISTICS */}
          {Object.keys(selectedCategories.resources).some(
            (key) => selectedCategories.resources[key]
          ) && (
            <div>
              <SectionTitle title="RESOURCE PLANNING & LOGISTICS" />
              <div className="space-y-4 mt-4">
                {selectedCategories.resources['Equipment'] && (
                  <div className="pl-4">
                    <h4 className="font-medium text-gray-900 mb-3">
                      Equipment
                    </h4>
                    <ContentItem
                      text="Three excavators (CAT 330) required for initial ground clearing"
                      source="Equipment Schedule"
                      page="4"
                      folderPath="Hospital Construction/Resources/Heavy Equipment"
                      context={{
                        before:
                          'Based on the project scope and timeline assessment,',
                        after:
                          'Each excavator must be equipped with GPS grade control systems and maintained according to manufacturer specifications.'
                      }}
                      tag={undefined}
                    />
                    <ContentItem
                      text="Two front-end loaders needed for material handling during site preparation"
                      source="Resource Planning"
                      page="7"
                      folderPath="Hospital Construction/Resources/Equipment Planning"
                      context={{
                        before:
                          'Material handling requirements have been assessed based on the project timeline.',
                        after:
                          'Support equipment may be adjusted based on soil conditions and weather considerations.'
                      }}
                      tag={undefined}
                    />
                  </div>
                )}

                {selectedCategories.resources['Materials and Supplies'] && (
                  <div className="pl-4">
                    <h4 className="font-medium text-gray-900 mb-3">
                      Materials and Supplies
                    </h4>
                    <ContentItem
                      text="Minimum 2000 square yards of erosion control fabric required"
                      source="Materials List"
                      page="9"
                      folderPath="Hospital Construction/Materials/Erosion Control"
                      context={{
                        before:
                          'To prevent soil erosion and maintain site stability,',
                        after:
                          'Installation must follow manufacturer specifications and be inspected weekly or after any rainfall event exceeding 0.5 inches.'
                      }}
                      tag={undefined}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* TIMEFRAMES */}
          {Object.keys(selectedCategories.timeframes).some(
            (key) => selectedCategories.timeframes[key]
          ) && (
            <div>
              <SectionTitle title="TIMEFRAMES" />
              <div className="space-y-4 mt-4">
                {selectedCategories.timeframes['Work Schedules'] && (
                  <div className="pl-4">
                    <h4 className="font-medium text-gray-900 mb-3">
                      Work Schedules
                    </h4>
                    <ContentItem
                      text="Site clearing must commence by September 15th, 2024"
                      source="Project Timeline"
                      page="2"
                      tag={undefined}
                      context={undefined}
                    />
                    <ContentItem
                      text="Completion of initial excavation required by October 30th, 2024"
                      source="Construction Schedule"
                      page="5"
                      tag={undefined}
                      context={undefined}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* GENERAL */}
          {Object.keys(selectedCategories.general).some(
            (key) => selectedCategories.general[key]
          ) && (
            <div>
              <SectionTitle title="GENERAL" />
              <div className="space-y-4 mt-4">
                {selectedCategories.general['Other Relevant Information'] && (
                  <div className="pl-4">
                    <ContentItem
                      text="Site located in seismic zone 3 requiring additional foundation considerations"
                      source="Site Analysis Report"
                      page="3"
                      folderPath="Hospital Construction/Site Assessment/Geotechnical"
                      context={{
                        before:
                          'Geological survey results indicate significant implications for construction planning.',
                        after:
                          'Additional reinforcement and specialized foundation design will be necessary to meet seismic requirements.'
                      }}
                      tag={undefined}
                    />
                    <ContentItem
                      text="Adjacent residential areas require noise reduction measures during construction"
                      source="Community Impact Assessment"
                      page="8"
                      folderPath="Hospital Construction/Planning/Environmental Impact"
                      context={{
                        before:
                          'Due to the proximity of residential neighborhoods,',
                        after:
                          'Work hours will be restricted and noise barriers must be installed along the western perimeter.'
                      }}
                      tag={undefined}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default NewChatLayout;
