import {
  Box,
  Button,
  Checkbox,
  Container,
  Header,
  LiveRegion,
  Multiselect,
  SpaceBetween
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/authContext';
import { createChatSummary } from '../../../helpers/createChatSummary';
import {
  ChatResponseSentence,
  IChatSummary,
  KBOptions
} from '../../../models/interfaces';
import { TopicSummary } from './topicSummary';
import { ProjectContext } from '../../../context/projectContext';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { DocumentContext } from '../../../context/documentContext';
import { bool } from 'aws-sdk/clients/signer';
import { LoadingBar } from '@cloudscape-design/chat-components';

export const ChatWithSummary = () => {
  const { token, auth } = useContext(UserContext);
  const { mainProjects, allFolders } = useContext(ProjectContext);
  const { allDocuments } = useContext(DocumentContext);
  const [inputText, setInputText] = useState<string>('');
  const [messages, setMessages] = useState<ChatResponseSentence[]>([]);
  const [summary, setSummary] = useState<any>({});
  const [topicSummary, setTopicSummary] = useState<IChatSummary>({});
  const [jobComplete, setJobComplete] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [projectSelectList, setProjectSelectList] = useState<
    OptionDefinition[]
  >([]);
  const [selectedProject, setSelecetedProject] = useState<OptionDefinition[]>(
    []
  );
  const [folderSelectList, setFolderSelectList] = useState<OptionDefinition[]>(
    []
  );
  const [selectedFolders, setSelectedFolders] = useState<OptionDefinition[]>(
    []
  );
  const [documentsSelectList, setDocumentSelectList] = useState<
    OptionDefinition[]
  >([]);
  const [selectedDocuments, setSelectedDocuments] = useState<
    OptionDefinition[]
  >([]);
  const [kbFilters, setKBFilters] = useState<OptionDefinition[]>([]);
  const [KBChecked, setKBChecked] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const setProjectsOptions = () => {
    const optionsArray = mainProjects
      .filter((ele) => ele.projectType === 'workspace')
      .map((ele) => {
        return {
          label: ele.projectName,
          value: ele._id
        };
      });
    setProjectSelectList(optionsArray);
  };
  useEffect(() => {
    if (mainProjects.length > 0) {
      setProjectsOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainProjects]);
  useEffect(() => {
    if (selectedProject.length > 0) {
      const projectIds = selectedProject.map((ele) => ele.value);
      const foldersArray = allFolders.filter((ele) =>
        projectIds.includes(ele.project)
      );
      const folderOptionList = foldersArray.map((ele) => {
        return { label: ele.folderName, value: ele._id };
      });
      setFolderSelectList(folderOptionList);
    } else {
      setFolderSelectList([]);
      setDocumentSelectList([]);
      setSelectedFolders([]);
      setSelectedDocuments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  useEffect(() => {
    if (selectedFolders.length === 0) {
      setDocumentSelectList([]);
      setSelectedDocuments([]);
    }
    if (allDocuments.length === 0) return;
    const folderIds = selectedFolders.map((ele) => ele.value);
    const filteredDocs = allDocuments.filter((ele) =>
      folderIds.includes(ele.folder)
    );
    const docOptions = filteredDocs.map((ele) => {
      return { label: ele.documentFileName, value: ele._id };
    });
    setDocumentSelectList(docOptions);
  }, [allDocuments, selectedFolders]);

  const searchAndAwait = async () => {
    setSearching(true);
    setMessages([]);
    setTopicSummary({});
    setSummary({});
    setJobComplete(false);
    setRequestLoading(true);
    const customHeaders = {
      Authorization: `Bearer ${token}`
    };
    const queryParams = new URLSearchParams(customHeaders).toString();
    const ws = new WebSocket(
      `wss://e99cr5vf84.execute-api.us-west-2.amazonaws.com/dev?${queryParams}`
    );
    ws.onopen = () => {
      console.log('WebSocket connection established');
      ws.send(
        JSON.stringify({
          action: 'sendMessage',
          message: [
            {
              text: inputText,
              _id: 'testId',
              messageToken: `${token}`,
              searchKB: KBChecked,
              documentIds: selectedDocuments.map((ele) => ele.value),
              kbType: kbFilters.map((ele) => ele.value)
            }
          ]
        })
      );
    };
    // Handle incoming messages
    ws.onmessage = (event) => {
      console.log('Message received:', event.data);
      // if (event.data === 'done') {
      //   ws.close();
      // }

      const parsedData = JSON.parse(event.data);
      if (parsedData.type === 'content' && parsedData.sentence) {
        console.log('content result', parsedData);
        setMessages((prevState) => [...prevState, parsedData.sentence]);
        // console.log('chat responses JSON', JSON.stringify(parsedData.sentence));
      }
      if (parsedData.type === 'summary') {
        setSummary(parsedData);
      }
      if (parsedData.type === 'sentence-complete') {
        setJobComplete(true);
        setSearching(false);
      }
    };
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setSearching(false);
    };
    // Handle connection close
    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };
  };
  const handleChatSummary = () => {
    console.log('messages to summary chat', messages);
    const summaryResult = createChatSummary(messages);
    console.log('topic summary', JSON.stringify(summaryResult));
    setTopicSummary(summaryResult);
  };
  useEffect(() => {
    if (jobComplete) {
      handleChatSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobComplete]);
  return (
    <Container>
      <SpaceBetween size={'xs'}>
        <div className="pb-6 border-gray-200">
          <h1 className="text-3xl font-bold text-gray-900 mb-3">
            KORU Intelligent Search
          </h1>
          <div className="max-w-3xl">
            <p className="text-gray-600">
              Discover important information with full traceability to key
              sections in your source documentation.
            </p>
          </div>
        </div>
        <Container
          header={
            <Header
              actions={
                <SpaceBetween size={'xs'}>
                  <Button onClick={() => searchAndAwait()} loading={searching}>
                    Search
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size={'xs'}>
                <Box variant="h3">Search & Filter</Box>
                <p>
                  Define your scope by selecting which documents to include.
                </p>
              </SpaceBetween>
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size={'xs'}>
            <div className="flex gap-4">
              <input
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                className="flex-1 px-4 py-2 border rounded-md bg-blue-50"
                placeholder="Search across documents..."
              />
            </div>
            <SpaceBetween direction="horizontal" size={'xs'}>
              <Box>Search In</Box>
              <SpaceBetween size={'xs'} direction="horizontal">
                <Checkbox
                  checked={KBChecked}
                  onChange={({ detail }) => setKBChecked(detail.checked)}
                >
                  Knowledge Base
                </Checkbox>
                {KBChecked && (
                  <Multiselect
                    onChange={({ detail }) =>
                      setKBFilters([...detail.selectedOptions])
                    }
                    // hideTokens
                    selectedOptions={kbFilters}
                    options={KBOptions}
                    placeholder={
                      kbFilters.length > 0
                        ? 'Use Selected Filters'
                        : 'Select Filters'
                    }
                    tokenLimit={0}
                    expandToViewport
                  />
                )}
              </SpaceBetween>
              <Box>+</Box>
              <SpaceBetween size="xs" direction="horizontal">
                <Multiselect
                  selectedOptions={selectedProject}
                  placeholder="Select Project"
                  options={projectSelectList}
                  onChange={({ detail }) =>
                    setSelecetedProject([...detail.selectedOptions])
                  }
                  tokenLimit={0}
                  expandToViewport
                />
                {folderSelectList.length > 0 && (
                  <Multiselect
                    placeholder="Select Folders"
                    selectedOptions={selectedFolders}
                    options={folderSelectList}
                    onChange={({ detail }) => {
                      console.log('dtail', detail);
                      setSelectedFolders([...detail.selectedOptions]);
                    }}
                    tokenLimit={0}
                    expandToViewport
                  />
                )}

                {documentsSelectList.length > 0 && (
                  <Multiselect
                    placeholder="Select Documents"
                    selectedOptions={selectedDocuments}
                    options={documentsSelectList}
                    onChange={({ detail }) => {
                      console.log('dtail', detail);
                      setSelectedDocuments([...detail.selectedOptions]);
                    }}
                    tokenLimit={0}
                    expandToViewport
                  />
                )}
              </SpaceBetween>
            </SpaceBetween>
          </SpaceBetween>
        </Container>
        {Object.keys(topicSummary).length > 0 ? (
          <TopicSummary topicSummary={topicSummary} />
        ) : null}
        {jobComplete && Object.keys(topicSummary).length === 0 && (
          <Container>
            <SpaceBetween direction="vertical" size={'xs'}>
              <Box variant="p">
                There appear to be no results for your search, please try a
                different search term or change the scope of your search.
              </Box>
            </SpaceBetween>
          </Container>
        )}
        {searching && (
          <LiveRegion>
            <Box
              margin={{ bottom: 'xs', left: 'l' }}
              color="text-body-secondary"
            >
              Searching
            </Box>
            <LoadingBar variant="gen-ai" />
          </LiveRegion>
        )}
      </SpaceBetween>
    </Container>
  );
};
